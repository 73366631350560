<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      내가 줄 리뷰 평점<router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_tab_1">
      <div>
        <router-link to="/mypage/after_evaluation">준 리뷰 평점</router-link>
      </div>
      <div class="on">
        <router-link to="/mypage/before_evaluation">줄 리뷰 평점</router-link>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_6">
      <div class="sect_sort">
        <select name="" v-model="sort" @change="getReviewEvalutation(true)">
          <option value="latest">최신순</option>
          <option value="old">오래된순</option>
        </select>
      </div>
      <div class="sect_table">
        <table summary="">
          <colgroup>
            <col width="*" />
            <col width="100px" />
          </colgroup>
          <thead>
            <tr>
              <th>리뷰</th>
              <th>평점 주러 가기</th>
            </tr>
          </thead>
          <tbody v-if="listArray.length > 0">
            <tr
              v-for="list in listArray"
              :key="list.seq"
              @click="goPage(list.seq)"
            >
              <td>{{ list.title }}</td>
              <td>
                <router-link to=""
                  ><img src="@/assets/images/sub/my_move_ico.png" alt=""
                /></router-link>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="2" :style="{ textAlign: 'center' }">
                평점 줄 리뷰가 없습니다.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!---->
    <div class="rn_more">
      <router-link
        to=""
        v-if="totalPage > page"
        @click.native="getReviewEvalutation()"
        >{{ limit }}개 더보기<img
          src="@/assets/images/sub/my_down_ico.png"
          alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      sort: "latest",
      page: 0,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.$store.dispatch("mypage/clearRammList");
    this.getReviewEvalutation(true);
  },
  computed: {
    ...mapState("mypage", [
      "result",
      "msg",
      "listArray",
      "totalPage",
      "limit",
      "checkAuth",
    ]),
  },
  methods: {
    async getReviewEvalutation(reset = false) {
      if (reset) {
        this.page = 0;
      }
      await this.$store.dispatch("mypage/getReviewEvalutation", {
        sort: this.sort,
        page: this.page,
        reset,
        type: "before",
      });
      this.page++;
    },
    goPage(seq) {
      const item = this.listArray.filter((list) => list.seq === seq);

      const category_code = item[0].options.split(",")[0];
      const option1 = item[0].options.split(",")[1];
      const option2 = item[0].options.split(",")[2];
      const option3 = item[0].options.split(",")[3];

      this.$router.push(
        `/review/goods_main/${category_code}?option1=${option1}&option2=${option2}&option3=${option3}&page=0&seq=${item[0].review_seq}`
      );
    },
  },
};
</script>

<style></style>
